<template>
 <div style="margin-top: 30px">
  <b-flix-h3>{{ $t('message.overview') }}</b-flix-h3>
  <div v-for="cal in calendar" :key="cal.ID">
    <b-flix-h5>{{ $tc('message.holidays', 2) }}: {{ cal.title }}</b-flix-h5>
    <tableList :id="cal.ID" @save="$emit('save')"></tableList>
  </div>
 </div>
</template>
<script>
export default {
  name: 'holidaysTable',
  components: {
    tableList () { return import('./tableList.vue') }
  },
  props: {},
  data () {
    return {
      calendar: this.$store.state.assistents.assistentsByID
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
  }
}
</script>
<style lang="sass" scoped>
</style>
